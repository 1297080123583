document.addEventListener('DOMContentLoaded', function () {
  let swiper = new Swiper("#cases-swiper", {
    slidesPerView: 2,
    autoHeight: false,
    spaceBetween: 0,
    pagination: {
      el: "#cases-swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: '#cases-swiper-button-next',
      prevEl: '#cases-swiper-button-prev',
    },
    breakpoints: {
      // when window width is >= 768px (md)
      768: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 0
      },
      // when window width is >= 640px (sm)
      640: {
        slidesPerView: 2,
        slidesPerGroup: 2,
        spaceBetween: 0
      },
    }
  });
})